<template>
  <div>
    <base-survey-answer
      v-if="surveyId && teachingDareId"
      style="padding-top: 5rem"
      :surveyId="parseInt(surveyId)"
      :teachingDareId="parseInt(teachingDareId)"
    ></base-survey-answer>
    <!-- <base-survey-answer
      style="padding-top: 5rem"
      :surveyId="12"
    ></base-survey-answer> -->
    <!-- <base-survey-answer
      style="padding-top: 5rem"
      :surveyId="13"
    ></base-survey-answer> -->
    <!-- <base-survey-answer
      style="padding-top: 5rem"
      :surveyId="14"
      :teachingDareId="3"
    ></base-survey-answer> -->
  </div>
</template>
<script>
import BaseSurveyAnswer from "../../components/form/BaseSurveyAnswer.vue";
export default {
  components: {
    BaseSurveyAnswer,
  },

  computed: {
    surveyId() {
      return this.$route.query.surveyId;
    },

    teachingDareId() {
      return this.$route.query.teachingDareId;
    },
  },
};
</script>